<template>
	<div>
		<div class="videotopTitle">
			<div class="videotopTitleNectx">
				<span class="mbm-iconfont mbm-shipin1" style="margin-right: 5px;margin-left: 3px;color: #000000;font-size: 20px;"></span>
				<span style="font-weight: 600;line-height: 16px;">视频转换链接、二维码</span>
			</div>
		</div>
		<div class="operateZone">
			<div class="operateZoneleft">
				<div :class="videoData.lists.length>0?'operateZoneleft1':'operateZoneleft1 operateZoneleftHover'" @click="videoData.lists.length==0?'uploadVideoFile':''">
					<div v-if="videoData.lists.length==0" class="operateZoneleftNodata" @click.stop="uploadVideoFile" style="text-align: center;">
						<!-- <img src="/img/addfile.png" alt="" style="width: 50px;height: 50px;"> -->
						<div class="addfilediv"></div>
						<p class="operateZoneleft11">点击或拖拽添加视频</p>
						<p class="operateZoneleft12">支持格式：mp4、mov、avi、flv 单个文件最大1G，单次支持上传50个</p>
						<!-- <p class="operateZoneleft12"></p> -->
						<input v-show="false" ref="fileRef" type="file" id="videoicker" accept="video/*" @change="videoAdd($event,'video')"
							multiple>
						<span @click.stop="addFromLib" class="addFromLib">从视频库导入</span>
					</div>
					<div v-else>
						<input @input="setTitle($event)" :value="videoData.title" type="text" placeholder="请输入标题 , 不输入则不显示" class="videoTitleBig">
						<div @dragover="dragover($event)">
							<transition-group name="sort" tag="div">
								<div class="operateZoneVideo" v-for="(item,index) in videoData.lists" v-bind:key='item'
									:draggable="true" @dragstart="dragstart(item)" @dragenter="dragenter(item,$event)"
									@dragend="dragend()" @dragover="dragover($event)">
									<div class="loadingCon" v-if="item.upload_hanlde.progress!=101">
										<a-progress strokeWidth="6" trailColor="white" :percent="item.upload_hanlde.progress" status="active" :show-info="false"/>
										<!-- <a-spin :tip="uploadPercent+'%'" /> -->
									</div>
									<div class="operateZoneVideo1">
										<div class="videoFm">
											<img v-if="item.cover&&item.cover.url" :src="$baseFunc.getQiniusign(item.cover.url)">
											<img v-else-if="item.url" :src="$baseFunc.getQiniusign(item.url,0)" />
											<img v-else src="" />
										</div>
										<div class="videoName">
											<div>
												<input @input="setVideotitle($event,index)" placeholder="请输入标题" class="videoName1" :id="'videoNameId'+index"
													:disabled="currentEdit==index?false:true" onfocus="this.select()"
													:value="item.title" />
												<span @click="editVideoName(index)" style="margin-left: 8px;"
													class="mbm-iconfont mbm-pen iconEdit"></span>
											</div>
											<div>
												<input @input="setVideoSubtitle($event,index)" class="videoName2" placeholder="请输入描述" :id="'videoSubNameId'+index"
													:disabled="currentSubEdit==index?false:true" onfocus="this.select()"
													:value="item.desc" />
												<span @click="editVideoSubName(index)" style="margin-left: 8px;"
													class="mbm-iconfont mbm-pen iconEdit"></span>
											</div>
										</div>
									</div>
									<div class="videoSize">{{item.filesize}}</div>
									<div class="videoSet">
										<span @click="showDeleteModal(index)">删除</span>
										<span @click="changeCover(index)">改封面</span>
										<span @click="changeVideo(index)">更换</span>
										<span @click="preview(index)">预览</span>
										<a-modal :mask="showModalMask" v-model:visible="delModalvisible" title="提示"
											@cancel="hideDeleteModal" @ok="deleteVideo()">
											<p>是否删除当前视频?</p>
										</a-modal>
									</div>
									
								</div>
							</transition-group>
						</div>
						<input v-show="false" type="file" id="coverChangeId" accept="image/*"
									@change="coverChange($event)">
						<input v-show="false" type="file" id="videoChangeId" accept="video/*"
									@change="videoChange($event)">
						<input v-show="false" type="file" id="addVideoId" multiple accept="video/*" @change="videoAdd($event)">
						<a-button  @click="addVideo" class="operateZoneAddvideo essential-color-main-btn">
							<template #icon><PlusCircleOutlined /></template>
							添加视频
						</a-button>
						<span @click="addFromLib" class="addFromLib">从视频库导入</span>
					</div>
				</div>
				<div class="operateZoneleft2">
					<a-button v-if="extra.path!==''&&videoData.change_type==1&&!dataChange.dataChange" @click="toDownloadQrcode" size="large" class="operateZoneSave essential-color-main-btn" type="primary">下载二维码</a-button>
					<a-button v-if="extra.qrcode_url!==''&&videoData.change_type==2&&!dataChange.dataChange" @click="copyUrl" size="large" class="operateZoneSave essential-color-main-btn" type="primary">复制链接</a-button>
					<a-button v-if="dataChange.dataChange&&videoData.change_type==1" :loading="generateQrcodeLoading" size="large" @click="toGenerate" class="operateZoneSave essential-color-main-btn" type="primary">{{generated.generated?generateQrcodeLoading&&addfileLoading?'正在上传':'保存修改':generateQrcodeLoading&&addfileLoading?'正在上传':'生成二维码'}}</a-button>
					<a-button v-if="dataChange.dataChange&&videoData.change_type==2" :loading="generateQrcodeLoading" size="large" @click="toGenerate" class="operateZoneSave essential-color-main-btn" type="primary">{{generated.generated?generateQrcodeLoading&&addfileLoading?'正在上传':'保存修改':generateQrcodeLoading&&addfileLoading?'正在上传':'生成链接'}}</a-button>
					<a-button v-if="generated.generated" size="large" @click="toPreview" class="operateZoneSave essential-color-main-btn">手机预览</a-button>
				</div>
			</div>
			<div class="operateZoneright">
				<div class="operateZoneright1">
					<div style="height: 118px;">
						<img v-if="videoData.change_type==1" :src="extra.path==''?'/img/qrcode.png':extra.path">
					</div>
					<div v-if="videoData.change_type==2" class="operateZoneright11">
						<span style="font-weight: 600;color: #f58a00;" v-if="extra.qrcode_url!=''">{{extra.qrcode_url}}</span>
						<input id="copytextId" type="text" style="position: absolute;left: 0;top: 0;opacity: 0;z-index: -10;">
						<span style="color: #f58a00;font-size: 14px;"  v-if="extra.qrcode_url==''">待生成 ...</span>
						<span @click="copyUrl" v-if="extra.qrcode_url!=''" class="corpyurl">复制</span>
					</div>
					<div class="operateZonerVip" @click="toVip">
						<span class="mbm-iconfont mbm-VIP"></span>升级VIP
					</div>
					<span v-if="videoData.change_type==1" class="operateZonerMh" @click="toDownloadQrcode">下载二维码</span>
				</div>
				<div class="operateZoneright2">
					<div class="default-cut-type-81js8" v-if="!videoData.createCodeStatus||videoData.createCodeStatus<1">
						<p class="operateZoneright2Title">转换形式</p>
						<div class="changeType">
							<div :class="videoData.change_type==2?'operateActive':''" @click="setChangetype(2)">
								<text>转链接</text>
								<span v-if="videoData.change_type==2" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
							</div>
							<div :class="videoData.change_type==1?'operateActive':''" @click="setChangetype(1)">
								<text>转二维码</text>
								<span v-if="videoData.change_type==1" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
							</div>
						</div>
					</div>
					<div v-if="videoData.change_type==1">
						<p class="operateZoneright2Title">打开方式<!-- <span>查看样例</span> --></p>
						<div class="openType">
							<div @click="setOpenType(1)" :class="videoData.mode==1?'operateActive':''">
								<img class="openTypeImg" src="/img/defult_chat.png" >
								<text>提片小程序</text>
								<span v-if="videoData.mode==1" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
							</div>
							<div @click="setOpenType(2)" :class="videoData.mode==2?'operateActive':''">
								<img class="openTypeImg" src="/img/chat.png" >
								<text>无标识小程序</text>
								<span v-if="videoData.mode==2" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
								<span class="mbm-iconfont mbm-VIP11 vip-logo-icon" ></span>
							</div>
							<div @click="setOpenType(3)" :class="videoData.mode==3?'operateActive':''">
								<img class="openTypeImg" src="/img/h5.png" >
								<text>H5网页</text>
								<span v-if="videoData.mode==3" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
								<span class="mbm-iconfont mbm-VIP11 vip-logo-icon" ></span>
							</div>
						</div>
					</div>
					<div v-if="videoData.change_type==2">
						<p class="operateZoneright2Title">域名选择<span style="display: none;">查看样例</span></p>
						<div class="urlType">
							<div @click="seturlType(1)" :class="videoData.url_info.url_type==1?'operateActive':''">
								<text>共享域名</text>
								<span v-if="videoData.url_info.url_type==1" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
							</div>
							<!-- <div @click="seturlType(2)" :class="videoData.url_info.url_type==2?'operateActive closed':'closed'">
								<text>专属域名</text>
								<span v-if="videoData.url_info.url_type==2" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
							</div> -->
							<div @click="seturlType(3)" :class="videoData.url_info.url_type==3?'operateActive closed':'closed'">
								<text>自有域名</text>
								<span v-if="videoData.url_info.url_type==3" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
							</div>
						</div>
						<div v-if="videoData.url_info.url_type==3" :class="vipUrlsOpen?'urlByVip urlByVip1':'urlByVip'" @click="UrlsOpen()">
							<span>{{videoData.url_info.url}}</span>
							<span v-if="videoData.url_info.url_type!=1" class="mbm-iconfont mbm-xiangxia" @click="UrlsOpen()"></span>
							<div v-if="vipUrlsOpen" class="vipUrls">
								<div v-for="item in vipUrllist" @click.stop="setVipurl(item.domain_name)">
									{{item.domain_name}}
								</div>
							</div>
						</div>
					</div>
					<div>
						<p class="operateZoneright2Title">视频款式<span @click="showVideotypeModal" style="display: none;">查看样例</span></p>
						<div class="videoType">
							<div @click="setVideoType(1)" :class="videoData.design==1?'operateActive':''">
								<text>标准</text>
								<span v-if="videoData.design==1" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
							</div>
							<div @click="setVideoType(2)" :class="videoData.design==2?'operateActive closed':''">
								<text>视频集</text>
								<span v-if="videoData.design==2" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
							</div>
							<div @click="setVideoType(3)" :class="videoData.design==3?'operateActive closed':''">
								<text>视频刷一刷</text>
								<span v-if="videoData.design==3" class="mbm-iconfont mbm-sanjiaogou1 yjy-gou-rb-box"></span>
							</div>
						</div>
					</div>
				</div>
				<div class="operateZoneright3">
					<div>
						<p class="operateZoneright3Title">{{videoData.change_type==1?'二维码':'链接'}}详细设置<span @click="setDetailSet">点击设置</span></p>
						<div class="detailSet">可设置：二维码查看权限、底部设置、下载设置等</div>
					</div>
					<div style="display: none;">
						<p class="operateZoneright3Title">样式显示设置<span @click="showstyleSet">点击设置</span></p>
						<div class="styleSet">可设置：左右撑满、底色、头像、标题样式等</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 视频显示样式设置 -->
		<a-modal okText="确认" cancelText='取消' :visible="stylesetVisible" :destroyInClose="true" :centered="true" width="1000px" title="设置" @cancel="closeStyleset_cancel()" @ok="closeStyleset_ok()">
			<styleSet @closeStyleset="closeStyleset_cancel" :videoData="videoData"></styleSet>
		</a-modal>

		<!-- 二维码详细设置 -->
		<a-modal :destroyInClose="true" :footer="null" :centered="true" width="1000px" :visible="showDetailSet" title="设置" @cancel="closeDetailSet_cancel" @ok="closeDetailSet_ok">
			<div class="preview_wrap">
				<settings :styleConfig="styleConfig" :showConfig="showConfig"  @getsetData="getsetData"  :idKey="videoData.change_type==1?extra.id_key_qr:extra.id_key_url"></settings>
			</div>
		</a-modal>
		
		<!-- 视频款式查看样例 -->
		<videoShowtype :mediatype="1" :showtypeVisible="showtypeVisible" @closeVideotypesetModal="closeVideotypesetModal"></videoShowtype>
		
		<!-- 预览-->
		<a-modal  :destroyInClose="true"  title="视频播放器" :visible="previewMode" @cancel="closepreview" :footer="null"
			:destroyOnClose="true" :width="600">
			<player :pageType="1" :info="previewData"></player>
		</a-modal>
			
	</div>
	
	
</template>

<script>
	import player from "./player.vue";
	import styleSet from "./styleSet.vue";
	import settings from "./settings.vue";
	import videoShowtype from "./videoShowtype.vue";
	import md5 from 'md5';
	import config from "../config/config.js";
	
	import baseFunc from '../config/base_func.js';
	import { message } from 'ant-design-vue';
	import { PlusCircleOutlined } from '@ant-design/icons-vue';
	import { v4 as uuidv4 } from 'uuid';
	import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
	import { createVNode, defineComponent } from 'vue';
	import { Modal } from 'ant-design-vue';
	
	export default {
		name: 'videotpl',
		props: {
			videoData:Object,
			extra:Object,
			// qrcode_url:String,//链接地址
			// path:String,// 二维码地址
			generated:Object,//是否已生成二维码或链接
			dataChange:Object,
		},
		components: {
			styleSet,settings,videoShowtype,player,PlusCircleOutlined
		},
		data() {
			return {
				uploadinfo:[],//上传状态
				userDefultSet:{},//默认设置
				// download_url:'',//下载地址
				previewMode:false,//预览模式
				previewData:{},//预览数据
				stylesetVisible:false,//样式显示设置
				videoDataCurrent:{},//
				vipUrllist: [],
				vipUrlsOpen: false, //专属域名展开收起
				currentEdit: -1, //当前编辑视频的下标
				currentSubEdit: -1,//
				showDetailSet:false,//二维码详细设置modal
				showtypeVisible:false,//视频显示样式Modal
				delModalvisible: false,
				showModalMask: false, //modal mask是否显示
				// datachanged:true,
				set_config:{
					'start_time': 0,/*二维码有效期 开始时间*/
					'end_time' : 0,/*二维码有效期 结束时间*/
					'time' : 0,
					'psw_tip' : '',//密码提示
					'password' : '',//密码
					'encryption' : 2,//1：输入密码查看 2：无需输入密码
					'start_time_quantum' : 0,//验证每天开始 11:00
					'end_time_quantum' : 0,//验证每天结束 12:00
					'time_horizon' : 2,//验证每天访问时间 1：开启 2：关闭
					'other_app' : 1,//允许非微信打开
					'chat_app' : 1,//允许微信打开
					'press' : 1,//允许长按识别进入
					'scan' : 1,//允许扫码进入
					'share' : 1,//允许微信分享进入
					'mode':2,//1默认微微小程序 2：无标识小程序  3：h5展示
					'video_download_code':0,//视频独立下载，0：未设置，1：可以下载 2：禁止下载
				},
				generateQrcodeLoading:false,//生成二维码时加载状态
				addfileLoading:false,
				h5_preview_url:'',//h5预览地址
				id_key_qrCode:'',//二维码的idkey
				id_key_url:'',//链接的idkey
				// generated:false,
				//样式设置，可不填
				styleConfig: {
					iframe: {
						// width:'200px',//默认320px
						// height:'400px',//默认900px
						//...
					},
					settings: {
						// width:'300px',//默认500px
						//...
					}
				},
				//显示内容设置，可不填
				showConfig: {
					iframe: true, //显示左侧预览iframe
					settings: true, //显示右侧设置整体
					settings_qrcode: true, //设置头部二维码图片+标题
					settings_list: true, //二维码设置部分收起/展开
					settings_list_expire: true, //设置有效期
					settings_list_auth: true, //设置查看权限
					settings_list_open: true, //设置打开方式（链接类型此项不论设置什么值最终都为false）
					settings_list_bot: true, //底部设置
					settings_list_video: true //视频下载设置
				},
			}
		},
		created() {
			this.getUserDefultSet(0);
		},
		watch:{
			videoData:{
				deep:true,
				handler(newval,oldval){
					this.dataChange.dataChange=true;
				}
				
			}
		},
		methods: {
			copyUrl(){
				var input = document.getElementById("copytextId");
				input.value = this.extra.qrcode_url;
				input.select();//选中文本
				document.execCommand("copy");
				message.success('复制成功');
			},
			getUserDefultSet(stauts,fn){
				this.$apiRequest.post('/user/getUserDefultSet', {
					
				}).then(res => {
					this.videoData.mode=res.data.data.mode;
					this.userDefultSet=res.data.data;
					if(stauts==1 && typeof fn=="function"){
						fn(res.data.data);
					}
				}).catch(err => {
					// message.error(err.message);
				})
			},
			toDownloadQrcode(){
				if(this.generated.generated){
					window.location.href=this.extra.download_url;
				}else{
					message.error('请先生成二维码或链接！');
				}
			},
			toVip(){
				window.open(config.upgradeUrl,"_blank");	
			},
			showstyleSet(){
				if(this.generated.generated){
					this.showDetailSet=true;
				}else{
					message.error('请先生成二维码或链接！');
				}
				// this.stylesetVisible=true;
			},
			closepreview(){
				
				this.previewMode=false;
			},
			preview(index){
				this.previewMode=true;
				var usedata=JSON.parse(JSON.stringify(this.videoData))
				this.previewData=usedata.lists[index];
				// this.previewData=this.videoData.lists[index];
				// if()
			},
			getsetData(res){
				this.videoData.title=res.qrcode_info.title;
				this.set_config=res.set_config;
				this.videoData.mode=res.set_config.mode;
			},
			closeDetailSet_ok(){
				this.showDetailSet=false
			},
			closeDetailSet_cancel(){
				this.showDetailSet=false
			},
			//手机预览
			toPreview(){
				if(this.generated.generated){
					this.showDetailSet=true;
				}else{
					message.error('请先生成二维码或链接！');
				}
			},
			setDetailSet(){
				if(this.generated.generated){
					this.showDetailSet=true;
				}else{
					message.error('请先生成二维码或链接！');
				}
			},
			closeStyleset_cancel(){
				this.stylesetVisible=false;
			},
			closeStyleset_ok(){
				this.stylesetVisible=false;
			},
			addFromLib(){
				this.$emit('showMedialists',true);
			},
			testFunc(){
				this.$router.push({
					name:'index',
					params:{
						type:1,
						id_key:'t1_xLnIX'
					}
				})
			},
			setVideotitle(event,index){
				this.videoData.lists[index].title=event.currentTarget.value
			},
			setVideoSubtitle(event,index){
				this.videoData.lists[index].desc=event.currentTarget.value
			},
			//生成二维码或链接
			toGenerate(){
				console.log(this.videoData)
				if(this.videoData.lists.length==0){
					message.error('请先选择上传文件！');
					return;
				}
				this.generateQrcodeLoading=true;
				this.$apiRequest.post('/qrcode/create',{
					type:'video',
					content:JSON.stringify(this.videoData),
					set_config:JSON.stringify(this.set_config),
					id_key:this.videoData.change_type==1?this.extra.id_key_qr:this.extra.id_key_url,
					parameter:''
				}).then(res => {
					this.generateQrcodeLoading=false;
					// console.log(res)
					if(res.data.code==200){
						this.h5_preview_url=res.data.data.h5_preview_url;
						message.success(res.data.message);
						if(this.videoData.change_type==1){
							this.extra.path=res.data.data.path;
							this.extra.id_key_qr=res.data.data.id_key;
							this.extra.download_url=res.data.data.download_url;
							this.generated.generated=true;
						}else if(this.videoData.change_type==2){
							this.extra.id_key_url=res.data.data.id_key;
							this.extra.qrcode_url=res.data.data.qrcode_url;
							this.generated.generated=true;
						}
						this.dataChange.dataChange=false;
						if(res.data.data.free_user_tips){
							this.$baseFunc.checkState({
								code:11001,
								message:res.data.data.free_user_tips,
							}).then(res => {
							}).catch(err=>{
								
							});
						}
					}else{
						this.$baseFunc.checkState(res.data).then(res => {
							toGenerate()
						});
					}
				}).catch(err => {
					this.generateQrcodeLoading=false;
					this.$baseFunc.checkState(err.data);
				});
			},
			//设置title
			setTitle(event){
				this.videoData.title=event.currentTarget.value
			},
			videoAdd(event){
				// 先初始化
				let currentData=this.videoDataCurrent.lists;
				let files = event.target.files;
				let videoObj={
					id: 0,
					sort: 0,
					title: "",
					desc: "",
					cover: {
						id: 0,
						url: "",
					},
					filesize: "0MB",
					url: "",
					width: 0,
					height: 0,
					duration: 0,
					extra: {
						title: "",
						desc: "",
						show_desc: 1,
						play_amount_set: 0,
						avatar: {
							id: 0,
							url: "",
						}
					},
					upload_hanlde:{
						progress:0,
					},
				};
				let all_total = files.length,have_upload = 0;
				for(let i=0;i<files.length;i++){
					this.addfileLoading=true;
					this.generateQrcodeLoading=true;
					this.videoData.lists.push(JSON.parse(JSON.stringify(videoObj)));
					let obj_i = this.videoData.lists.length-1;
					
					this.$apiRequest.uploadMediaToQiniu(this,files[i],progress=>{
						/*进度条*/
						this.videoData.lists[progress.extra_data_defult.current].upload_hanlde.progress=progress.percent;
					},complete=>{
						
					},{
						"upload_type":"video",/*video：只能上传视频 audio：只能上传音频 media：音视频都可以上传（默认）*/
						"current":obj_i,
					}).then(res => {
						have_upload++
						this.videoData.lists[res.extra_data_defult.current].upload_hanlde.progress=101;
						this.videoData.lists[res.extra_data_defult.current].title=res.filename;
						this.videoData.lists[res.extra_data_defult.current].filesize=res.filesize;
						this.videoData.lists[res.extra_data_defult.current].width=res.width;
						this.videoData.lists[res.extra_data_defult.current].height=res.height;
						this.videoData.lists[res.extra_data_defult.current].id=res.id;
						this.videoData.lists[res.extra_data_defult.current].url=res.url;
						if(have_upload==all_total){
							this.addfileLoading=false;
							this.generateQrcodeLoading=false;
						}
					}).catch(err => {
						have_upload++
						if(have_upload==all_total){
							this.addfileLoading=false;
							this.generateQrcodeLoading=false;
						}
						this.videoData.lists.length=this.videoData.lists.length-1;
					});
				}
					
				
				/*清空input*/
				var obj = document.getElementById("addVideoId"); 
				if(obj){
					obj.value="";
				}
			},
			videoChange(event,index){
				let files = event.target.files;
				let currentData=JSON.parse(JSON.stringify(this.videoData.lists[this.currentEdit]));
				this.videoData.lists[this.currentEdit].upload_hanlde.progress=0;
				this.generateQrcodeLoading=true;
				this.addfileLoading=true;
				this.$apiRequest.uploadMediaToQiniu(this,files[0],progress=>{
					/*进度条*/
					this.videoData.lists[progress.extra_data_defult.current].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
				},complete=>{
					
				},{
					"upload_type":"video",/*video：只能上传视频 audio：只能上传音频 media：音视频都可以上传（默认）*/
					"current":this.currentEdit,
				}).then(res => {
					this.videoData.lists[res.extra_data_defult.current].upload_hanlde.progress=101;
					this.videoData.lists[res.extra_data_defult.current].title=res.filename;
					this.videoData.lists[res.extra_data_defult.current].filesize=res.filesize;
					this.videoData.lists[res.extra_data_defult.current].width=res.width;
					this.videoData.lists[res.extra_data_defult.current].height=res.height;
					this.videoData.lists[res.extra_data_defult.current].id=res.id;
					this.videoData.lists[res.extra_data_defult.current].url=res.url;
					this.generateQrcodeLoading=false;
					this.addfileLoading=false;
				}).catch(err => {
					this.videoData.lists[err.extra_data_defult.current]=currentData;
					this.generateQrcodeLoading=false;
					this.addfileLoading=false;
				});
				
				var obj = document.getElementById("videoChangeId") ; 
				if(obj){
					obj.value="";
				}
			},
			coverChange(event){
				let files = event.target.files;
				let currentData=JSON.parse(JSON.stringify(this.videoData.lists[this.currentEdit]));
				this.videoData.lists[this.currentEdit].upload_hanlde.progress=0;
				this.generateQrcodeLoading=true;
				this.addfileLoading=true;
				this.$apiRequest.uploadMediaToQiniu(this,files[0],progress=>{
					/*进度条*/
					this.videoData.lists[progress.extra_data_defult.current].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
				},complete=>{
					
				},{
					"upload_type":'img',
					"current":this.currentEdit
				}).then(res => {
					this.videoData.lists[res.extra_data_defult.current].upload_hanlde.progress=101;
					this.videoData.lists[res.extra_data_defult.current].cover.id=res.id;
					this.videoData.lists[res.extra_data_defult.current].cover.url=res.url
					this.generateQrcodeLoading=false;
					this.addfileLoading=false;
				}).catch(err => {
					this.videoData.lists[res.extra_data_defult.current]=currentData;
					this.generateQrcodeLoading=false;
					this.addfileLoading=false;
				});
				/*清空input*/
				var obj = document.getElementById("coverChangeId") ; 
				if(obj){
					obj.value="";
				}
			},
			hideDeleteModal() {
				this.delModalvisible = false;
			},
			showDeleteModal(index) {
				var that=this;
				that.currentEdit = index;
				Modal.confirm({
					title: () => '提示',
					icon: () => createVNode(ExclamationCircleOutlined),
					cancelText:'取消',
					centered:true,
					okText:'确认删除',
					okType: 'danger',
					content: () => '是否删除当前视频？',
					onOk() {
					  that.videoData.lists.splice(that.currentEdit, 1);
					},
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onCancel() {},
				});
			},
			
			//*****************************************拖动排序
			dragstart(value) {
				this.oldData = value
			},
			// 记录移动过程中信息
			dragenter(value, e) {
				this.newData = value
				e.preventDefault()
			},
			// 拖拽最终操作
			dragend() {
				if (this.oldData !== this.newData) {
					let oldIndex = this.videoData.lists.indexOf(this.oldData);
					let newIndex = this.videoData.lists.indexOf(this.newData);
					let newItems = [...this.videoData.lists];
					newItems.splice(oldIndex, 1);
					newItems.splice(newIndex, 0, this.oldData);
					this.videoData.lists = [...newItems];
				}
			},
			// 拖动事件（主要是为了拖动时鼠标光标不变为禁止）
			dragover(e) {
				e.preventDefault()
			},
			//*******************************************拖动排序
			showVideotypeModal(){
				this.showtypeVisible=true;
			},
			closeVideotypesetModal(){
				this.showtypeVisible=false;
			},
			
			
			//修改视频名字editVideoSubName
			editVideoSubName(e) {
				this.currentSubEdit = e;
				this.currentEdit = -1;
				var id = 'videoSubNameId' + e;
				this.$nextTick(function() {
					document.getElementById(id).focus();
				})
			},
			editVideoName(e) {
				this.currentEdit = e;
				this.currentSubEdit = -1;
				var id = 'videoNameId' + e;
				this.$nextTick(function() {
					document.getElementById(id).focus();
				})
			},
			//添加视频
			addVideo() {
				this.currentEdit=-1;
				this.videoDataCurrent=JSON.parse(JSON.stringify(this.videoData))
				document.getElementById('addVideoId').dispatchEvent(new MouseEvent('click'))
			},
			//h换封面
			changeCover(e) {
				document.getElementById('coverChangeId').dispatchEvent(new MouseEvent('click'))
				this.currentEdit=e;
			},
			//换视频
			changeVideo(e) {
				this.currentEdit=e;
				document.getElementById('videoChangeId').dispatchEvent(new MouseEvent('click'))
			},

			//选择视频
			uploadVideoFile() {
				this.videoDataCurrent=JSON.parse(JSON.stringify(this.videoData))
				this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))
			},
			//获取本地视频地址
			fileChange(e) {
				var that = this;
				var files = document.getElementById("videoicker").files;
				var urls = [];
				for (var i = 0; i < files.length; i++) {
					var url = URL.createObjectURL(files[i]);
					urls.push(url)
				}
				that.videoData.lists = urls
			},
			UrlsOpen() {
				this.vipUrlsOpen = !this.vipUrlsOpen
			},
			setVipurl(e) {
				this.videoData.url_info.url = e;
				this.vipUrlsOpen = false;
			},
			//设置转换形式
			setChangetype(e) {
				if(this.videoData.change_type==2&&this.extra.path!=''){
					this.generated.generated=true;
					
				}else if(this.videoData.change_type==1&&this.extra.qrcode_url!=''){
					this.generated.generated=true;
					
				}else{
					this.generated.generated=false;
				}
				this.videoData.change_type = e;
			},
			//设置打开方式
			setOpenType(e) {
				const that = this;
				if(this.videoData.mode!=e){
					if(e==1 && this.userDefultSet.vip_status==1){
						this.videoData.mode = e;
					}else{
						this.getUserDefultSet(1,function(ret){
							if(ret.vip_status==1){
								that.videoData.mode = e;
							}else{
								that.$baseFunc.checkState({
									code:11001,
									message:ret.mode_message
								}).then(res=>{
									
								}).catch(err=>{
									
								});
							}
						});
					}
				}
			},
			
			seturlType(e) {
				if(e!=1){
					message.error('即将开放！');
				}else{
					this.videoData.url_info.url_type = e;
				}
				// this.vipUrlsOpen = false;
				// this.videoData.url_info.url_type = e;
				// if(e==3){
				// 	this.getOwnurl()
				// }
			},
			//获取可用自有域名
			getOwnurl(){
				this.$apiRequest.post('/domain/list',{
					type:'valid'
				}).then(res=>{
					console.log(res.data.data)
					this.vipUrllist=res.data.data;
				})
			},
			setVideoType(e) {
				if(e==2){
					if(config.devEnv=='dev'){
						window.open('/createUrlAndCode.html');
					}else{
						window.open('/bofangqi');
					}
				}else if(e==3){
					if(config.devEnv=='dev'){
						window.open('/videoSlider.html');
					}else{
						window.open('/shua');
					}
				}else if(e!=1){
					message.error('即将开放！');
				}else{
					this.videoData.design = e;
				}
				// this.videoData.design = e;
			}
		}
	}
</script>

<style scoped>
	
	.operateZoneleftHover:hover .addfilediv{
		background-image: url(/img/video2.png);
	}
	.addfilediv{
		width:50px;
		height:50px;
		background-image: url(/img/video.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		margin:0 auto;
	}
	.videoType>div,.urlType>div{
		position:relative;
	}
	.openTypeImg{
		width:17px;
		height:17px;
		margin-right:3px;
	}
	.yjy-gou-rb-box{
		position: absolute;
		right:-1px;
		bottom:-1px;
		font-size:20px;
		line-height: 20px;
		color: #285af9;
	}
	.openType>div{
		display:flex;
		justify-content: center;
		align-items: center;
	}
	.videoType>div:hover,.changeType>div:hover,.urlType>div:hover,.openType>div:hover{
		border:1px solid #285af9;
		transition: all .3s;
	}
	.closed{
		border:1px solid #efefef !important;
		color:#999 !important;
	}
	.viplogo{
		width:30px;
		height:30px;
		position: absolute;
		right:0;
		top:-6px;
	}
	.vip-logo-icon{
		width: 26px;
		height: 18px;
		position: absolute;
		right: 0;
		top: 0;
		color: #fd9f07;
		line-height: 1;
		font-size: 18px;
	}
	.preview_wrap{width:100%;margin:0 auto;display:flex;justify-content:space-between;height:700px;	overflow-y: scroll;}
	.operateZoneright2Title span:hover,.operateZoneright3Title span:hover{
		color: #285af9;
		transition: all .3s;
	}
	.qrcodeSetCon{
		display:flex;
		justify-content: center;
		align-items: center;
	}
	.loadingCon{
		width: 100%;
		height: 100%;
		position: absolute;
		left:0;
		bottom:0;
		background-color: #edf3fc;
		z-index:20;
		display:flex;
		justify-content: center;
		border-radius:8px;
		align-items: center;
		padding:0 100px;
	}
	.sort-move {
		transition: transform .3s;
	}
	.addFromLib:hover{
		color: #285af9;
		transition: all .3s;
		
	}
	.addFromLib {
		position: absolute;
		bottom: 10px;
		right: 10px;
		font-size: 12px;
		cursor: pointer;
		color: #585858;
		padding:5px;
	}

	.videoTitleBig::-webkit-input-placeholder {
		color: #999;
	}

	.videoTitleBig {
		width: 100%;
		border: 0;
		outline: none;
		background-color: white;
		text-align: center;
		font-size: 16px;
		height: 16px;
		margin-bottom: 20px;
	}

	.iconEdit {
		cursor: pointer;
		display: none;
	}

	.operateActive {
		border: 1px solid #285af9 !important;
	}

	.urlByVip1 {
		border-bottom: 1px solid #3d91d7 !important;
	}

	.vipUrls>div:hover {
		background-color: #f3faff;
	}

	.vipUrls>div {
		line-height: 32px;
		padding: 0 20px;
	}

	.vipUrls {
		width: 100%;
		height: 160px;
		box-shadow: 0 0 10px #f1f1f1;
		position: absolute;
		cursor: pointer;
		left: 0;
		top: 36px;
		background-color: #fff;
		overflow-y: scroll;
		z-index:999;
	}

	.urlByVip {
		height: 32px;
		border-bottom: 1px solid #585858;
		width: 310px;
		line-height: 32px;
		font-size: 12px;
		color: #999;
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		position: relative;
		cursor: pointer;
	}

	.corpyurl {
		display: inline-block;
		width: 35px;
		border-radius: 5px;
		background-color: #f58a00;
		color: #ffe699;
		font-size: 12px;
		line-height: 20px;
		margin-left: 10px;
		text-align: center;
		cursor: pointer;
	}

	.operateZoneright11 {
		height: 100px;
		background-color: #fffcee;
		border-radius: 8px;
		border: 1px dashed #faedaf;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		line-height: 18px;
	}

	.videoName>div:hover span {
		display: block;
	}

	.videoName>div {
		display: flex;
		align-items: center;
		width: 205px;
	}

	.videoSize {
		text-align: center;
		width: 100px;
		font-size: 12px;
		color: #585858;
	}

	.videoName2 {
		margin-top: 8px;
	}

	.videoName1,
	.videoName2 {
		border: 0;
		outline: none;
		margin-left: 9px;
		font-size: 12px;
		background-color: white;
		max-width: 165px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.operateZoneVideo1 {
		display: flex;
		align-items: center;

	}
	.videoSet span {
		font-size: 12px;
		color: #585858;
		margin-right: 10px;
		cursor: pointer;
	}
	.videoSet span:hover{
		color:#285af9;
		transition: all .3s;
	}
	.videoFm img {
		max-width: 100%;
		max-height: 100%;
	}
	.videoFm video{
		width:100%;
		height:100%;
	}
	.videoFm {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 92px;
		height: 54px;
		background-color: #e0ded9;
		border-radius: 4px;
	}

	.operateZoneVideo {
		width: 100%;
		height: 69px;
		border-radius: 8px;
		margin-bottom: 20px;
		border: 2px solid #fff;
		padding-left: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position:relative;
	}
	.operateZoneleft1 .ant-btn:hover,.operateZoneleft1 .ant-btn:focus{
		color: #285af9;
		background: #fff;
		border-color: #285af9;
	}
	.operateZoneVideo:hover {
		border: 2px solid #285af9;
	}

	.operateZoneAddvideo {
		width: 584px;
		height: 51px;
		background-color: #f8f8f8;
		border:1px solid #f8f8f8;
		border-radius: 8px;
		text-align: center;
		font-size: 14px;
		color: #585858;
		margin-top: 8px;
		margin-left:8px;
		cursor: pointer;
		margin-bottom: 30px;
	}

	.operateZoneleftNodata {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		height: 420px;
		cursor: pointer;
		padding-top:115px;
	}

	.detailSet,
	.styleSet {
		font-size: 12px;
		color: #585858;
		line-height: 12px;
	}

	.operateZoneright3Title {
		font-size: 14px;
		line-height: 14px;
		color: #333;
		margin-top: 22px;
		margin-bottom: 11px;
	}

	.operateZoneright3 {
		padding-top: 10px;
	}

	.operateZoneright2Title span,
	.operateZoneright3Title span {
		font-size: 12px;
		color: #38589e;
		text-decoration: underline;
		margin-left: 7px;
		cursor: pointer;
	}

	.changeType,
	.openType {
		display: flex;
		justify-content: space-between;
	}

	.videoType,
	.urlType {
		display: flex;
	}

	.urlType>div {
		width: 95px;
		height: 32px;
		border: 1px solid #e7e7e7;
		border-radius: 4px;
		font-size: 14px;
		color: #333;
		line-height: 30px;
		text-align: center;
		margin-right: 12px;
		cursor: pointer;
	}

	.openType>div {
		width: 132px;
		height: 56px;
		border: 1px solid #e7e7e7;
		border-radius: 4px;
		font-size: 14px;
		color: #333;
		line-height: 54px;
		text-align: center;
		cursor: pointer;
		position:relative;
	}

	.videoType>div {
		width: 95px;
		height: 32px;
		border: 1px solid #e7e7e7;
		border-radius: 4px;
		font-size: 14px;
		color: #333;
		line-height: 30px;
		text-align: center;
		margin-right: 12px;
		cursor: pointer;
	}

	.changeType>div {
		width: 194px;
		height: 32px;
		border: 1px solid #e7e7e7;
		border-radius: 4px;
		font-size: 14px;
		color: #333;
		line-height: 30px;
		text-align: center;
		cursor: pointer;
		position:relative;
	}
	

	.operateZoneright2Title {
		font-size: 14px;
		line-height: 14px;
		color: #333;
		margin-top: 22px;
		margin-bottom: 11px;
	}
	.operateZonerMh:hover{
		color:#285af9;
	}
	.operateZonerMh {
		font-size: 12px;
		color: #999;
		position: absolute;
		right: 0;
		bottom: 10px;
		cursor: pointer;
	}

	.mbm-VIP{
		font-size: 12px;
		margin-right: 6px;
	}
	.mbm-pen{
		font-size: 12px;
	}
	.operateZonerVip:hover{
		color:#ffa805;
	}
	.operateZonerVip {
		font-size: 12px;
		color: #ffb813;
		position: absolute;
		left: 0px;
		bottom: 10px;
		cursor: pointer;
	}

	.operateZoneright1 {
		display: flex;
		justify-content: center;
		padding-bottom: 39px;
		border-bottom: 1px dashed #e7e7e7;
		position: relative;
	}

	.operateZoneright1 img {
		width: 118px;
		height: 118px;
	}

	.operateZoneright {
		padding: 30px 23px 0;
	}

	.operateZoneView {
		width: 155px !important;
		height: 36px !important;
	}

	.operateZoneSave {
		width: 155px !important;
		/* height: 36px !important; */
		margin: 0 8px;
	}

	.operateZoneleft2 {
		margin-top: 52px;
		display: flex;
		justify-content: center;
		margin-bottom: 55px;
	}

	.mbm-shipin1111 {
		font-size: 50px !important;
		cursor: pointer;
		color:#;
	}

	.operateZoneleft12 {
		font-size: 12px;
		color: #999;
		margin-top: 29px;
		line-height: 12px;
	}

	.operateZoneleft11 {
		font-size: 16px;
		line-height: 16px;
		color: #333;
		margin-top: 27px;
		transition: all 0.3s;
	}

	.operateZoneleft1 {
		width: 100%;
		min-height: 420px;
		border: 1px dashed #e7e7e7;
		border-radius: 8px;
		position: relative;
		padding: 20px 25px;
		transition: all 0.3s;
	}
	.operateZoneleftHover:hover{
		border-color:#285af9;
	}
	.operateZoneleft1:hover .operateZoneleft11{
		color: #285af9;
	}

	.operateZoneleft {
		width: 712px;
		min-height: 600px;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 10px 20px #ededed;
		padding: 30px 30px 0;
	}

	.operateZoneright {
		width: 465px;
		height: 600px;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 10px 20px #ededed;
	}

	.operateZone {
		margin-top: 13px;
		display: flex;
		justify-content: space-between;
	}

	.videotopTitle {
		padding-top: 43px;
		font-size: 16px;
		display: flex;
		align-items: center;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	.mbm-shipin {
		font-size: 25px;
		color: #333;
		margin-right: 15px;
	}
</style>

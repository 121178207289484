module.exports = {
  devEnv:"online",/*dev:开发版本，online：线上版本*/
  baseURL1:"http://local.tipian.com",/*测试-vue*/
  baseURL1:"http://192.168.1.152:8050",/*测试-东莞-涛*/
  baseURL1:"http://192.168.1.159:8082",/*测试-东莞-勇*/
  baseURL:"",/*正式*/
  baseMtURL1:"http://h5api.tipian.com",/*测试-移动端预览*/
  baseMtURL1:"http://192.168.1.152:8011",/*测试-移动端预览-涛*/
  baseMtURL:"",/*正式线上*/
  upgradeUrl:"https://denglu.tipian.com/price",/*升级会员vip*/ 
  apiAppid:"uoxkse45j0563605",/*api请求appid*/
  apiAppkey:"eJfaqwDLPNktIpQUQAoehPm6iLwW77sH",/*api请求appkey*/
  qiniuMediaDomain:"//nsj.tipian01.cn",/*七牛云注册的空间域名*/
  qiniuTimeKey:'8ea8a79bc1aa1388fcbbe59b3298ef880a995689',//七牛云时间防盗链备用key
  aliMainKey:'o6W5KVMevCRvBKf9c7pHIA0Xnumm0wPF',/*阿里云加密主key*/
  mianDomain:'https://www.tipian.com',//主域名
  dengluDomain:'https://denglu.tipian.com',//个人中心域名
  h5Urls:{
	home:'/home',/*h5个人中心*/
	set:'/home/set',/*h5个人中心设置*/
	order:'/home/order',/*h5订单中心*/
  },
  aliOSS:{
	region: 'oss-cn-shenzhen',
	accessKeyId: 'LTAID2mtjY1XqJWm',
	accessKeySecret: '7THnyDvrZAJjbXbP8tJ0Uvi9ncFTDM',
	bucket: 'wweicode',
	outDomain:'//net-img.popziti.com',
  },
  aliOSSOther:{
  	region: 'oss-cn-hangzhou',
  	accessKeyId: 'LTAI5tPLfpQTdje6v5zUf4y5',
  	accessKeySecret: 'lAsROzPQwjfmEPLl2M0aJJI54ptGEo',
  	bucket: 'tipian-attach',
  	outDomain:'//imgfile.tipian01.cn',
	secure:true,
  }
}
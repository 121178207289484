<template>
	<div>
		<div class="ck_editor_tool_menu">
			<div id="ck_editor_tool_header" class="ck_editor_tool_header"></div>
			<div class="ck_editor_tool_header_default">
				<div class="ck_editor_tool_header_default-item" @click="imgData.editorAddImg"><span class="mbm-iconfont mbm-tupian"></span></div>
			</div>
		</div>
		<textarea  id="ckEditorTextarea" name="content" contenteditable="true"></textarea>
		
		<!-- 编辑器上传图片 -->
		<input ref="editorContentUploadImg" type="file" @change="imgData.selectImageFile($event)" accept="image/*" class="editor-upload-img-file">
		<!-- 图片管理 -->
		<a-modal v-model:visible="imgData.visible" title="" width="750px" cancelText="取消" :okText="确认" centered :closable="false"  :destroyOnClose="true"
		:confirmLoading="imgData.submitLoad"  @ok="imgData.saveOk">
			<div class="editor-images-upload">
				<div v-for="(imgItem,index) in imgData.lists" :key="imgItem.unique_id" class="editor-image-preview">
					 <a-image width="100%" height="100%" style="object-fit: contain;" :src="imgItem.preview_url?imgItem.preview_url:imgItem.url" />
					 <div v-if="!imgItem.status" @click="imgData.deleteImageFile(index)" class="editor-images-del"><span class="mbm-iconfont mbm-guanbi2fill"></span></div>
					 <div v-if="imgItem.status=='uploading'" class="editor-images-uploading">
						 <div class="editor-images-uploading-item">
							<div>{{imgItem.progress}}%</div>
							<a-progress strokeWidth="6" trailColor="white" :percent="imgItem.progress" status="active" :show-info="false"/>
						 </div>
					 </div>
				</div>
				<div class="editor-image-add" v-if="imgData.lists.length<5"  @click="imgData.editorAddImg">
					<PlusOutlined style="font-size: 22px;" />
					<div class="community-upload-text">添加图片</div>
				</div>
			</div>
		</a-modal>
		
		<!-- 图片管理 -->
		<a-modal v-model:visible="linkData.visible" title="" width="600px" cancelText="取消" :okText="确认" centered :closable="false"  :destroyOnClose="true"  @ok="linkData.saveOk">
			<div>
				<div style="margin-bottom: 16px;color: #333;font-size: 16px;">网址</div>
				<a-input v-model:value="linkData.val" placeholder="请输入网址" />
			</div>
		</a-modal>
	</div>
	
</template>

<script>
	let editorModal = null;
	var moduleDialogData={dialog:null,widget:null,type:''};
	import { PlusOutlined, LoadingOutlined  } from '@ant-design/icons-vue';
	import { v4 as uuidv4 } from 'uuid';
	import { message } from 'ant-design-vue';
	import baseFunc from '/src/config/base_func.js';
	export default {
		name: 'Ckeditor',
		components: {
			PlusOutlined, LoadingOutlined
		},
		props: {
			content: {
			    type: String,
			    default: ""
			},
		},
		watch:{
		},
		data(){
			return{
				imgData:{
					visible:false,/*显示图片上传*/
					submitLoad:false,/*确认添加-load*/
					lists:[],
					editorAddImg:()=>{
						this.$refs.editorContentUploadImg.dispatchEvent(new MouseEvent('click'))
					},
					deleteImageFile:(index)=>{
						this.imgData.lists.splice(index,1);
					},
					selectImageFile:(e)=>{
						let file = e.target.files[0];
						let checkExt = baseFunc.checkFileExt(baseFunc.getType(file.name,true),"img");
						if(checkExt){
							message.error(checkExt);
							return false;
						}
						if(file.size>20*1024*1024){
							message.error("最大支持20M，您选择的图片为"+baseFunc.converSize(file.size));
							return false;
						}
						
						if(!this.imgData.visible){
							this.imgData.visible = true;
							this.imgData.lists=[];
							this.imgData.submitLoad=false;
						}
						
						let fileData={
							unique_id:uuidv4(),
							url:'',
							preview_url:'',
							attach_id:0,
							filename:file.name,
							filesize_byte:file.size,
							filesize:baseFunc.converSize(file.size),
							progress:0,
							status:null,//uploading：上传中 success:上传成功
							file:file,
						}
						this.imgData.lists.push(fileData);
						let index = this.imgData.lists.indexOf(fileData)
						try{
							this.imgData.lists[index].preview_url=URL.createObjectURL(file)
						}catch(err){
							let img = new FileReader();
							img.readAsDataURL(file);
							img.onload = ({target}) => {this.imgData.lists[index].preview_url=target.result};
						}
						this.$refs.editorContentUploadImg.value = null
					},
					/*循环上传图片*/
					async _uploadLoop(that){
						let fileAttachs = that.imgData.lists,
							proms=[];
						for(let i=0;i<fileAttachs.length;i++){
							if(fileAttachs[i].status){
								continue;
							};
							that.imgData.lists[i].status="uploading";
							proms.push(
							    await new Promise((resolve,reject)=>{
									that.$apiRequest.uploadFileToAliOssOther(that,fileAttachs[i].file,{
										"index":i,
									},progress=>{
										/*进度条*/
										that.imgData.lists[progress.extra_data_defult.index].progress=progress.percent;
									},complete=>{
										// console.log("complete",complete)
									}).then(res => {
										that.imgData.lists[res.extra_data_defult.index].status="success";
										that.imgData.lists[res.extra_data_defult.index].url=res.url;
										that.imgData.lists[res.extra_data_defult.index].attach_id=res.id;
										that.imgData.lists[res.extra_data_defult.index].file=null;
										resolve()
									}).catch(err => {
										reject()
									});
								})
							)
						}
						return Promise.all(proms)
					},
					saveOk:()=>{/*确认添加*/
						this.imgData.submitLoad=true;
						this.imgData._uploadLoop(this).then(()=>{
							let _img_html = '';
							for(let i=0;i<this.imgData.lists.length;i++){
								if(this.imgData.lists[i]){
									_img_html+=`<div class="img_h"><img src="${this.imgData.lists[i].url}"/></div>`;
								}
							}
							if(_img_html)insertHtml(`<section>${_img_html}</section>`,'img');
							this.imgData.submitLoad=false;
							this.imgData.visible=false;
							this.imgData.lists=[];
						}).catch(err => {
							this.imgData.submitLoad=false;
							message.error("图片上传失败，请重新上传")
						})
					},
				},
				linkData:{
					visible:false,/*显示图片上传*/
					val:"",/*编辑内容*/
					openLink:(data)=>{
						this.linkData.visible=true;
						if(typeof data.type !='undefined'){
							if(!data.url.protocol)data.url.protocol="";
							this.linkData.val=data.url.protocol+data.url.url;
						}else{
							this.linkData.val="";
						}
						console.log(data)
					},
					saveOk:()=>{/*确认添加*/
						let linkProtocol="",linkUrl="";
						let linkVal = this.linkData.val;
						if(linkVal){
							if(linkVal.indexOf("http://") < 0&&linkVal.indexOf("https://") < 0&&linkVal.indexOf("ftp://") < 0&&linkVal.indexOf("rtsp://") < 0&&linkVal.indexOf("mms://") < 0){
								linkVal = 'http://'+linkVal;
							}
							try{
								let linkParul = new URL(linkVal);
								if(linkParul.protocol && ["https:","http:","ftp:","news:"].includes(linkParul.protocol)){
									linkProtocol=linkParul.protocol+"//";
									linkUrl = linkParul.host+(inkParul.pathname!='/'?linkParul.pathname:'')+linkParul.search;
								}else{
									linkUrl = linkVal;
								}
							}catch (e) {
								linkUrl = linkVal;
							};
						}
						if(!$('#'+moduleDialogData.dialog._.contents.info.linkDisplayText.domId).find("input").val()){
							$('#'+moduleDialogData.dialog._.contents.info.linkDisplayText.domId).find("input").val(this.linkData.val);
						}
						$('#'+moduleDialogData.dialog._.contents.info.urlOptions.domId).find("select").val(linkProtocol);
						$('#'+moduleDialogData.dialog._.contents.info.urlOptions.domId).find("input").val(linkUrl);
						$(moduleDialogData.dialog.parts.dialog.$).find(".cke_dialog_ui_button_ok")[0].click();
						moduleDialogData={dialog:null,widget:null,type:''}
						this.linkData.visible=false;
						this.linkData.val="";
					},
				},
				ckeditor:{
					content:"请输入正文内容",
				}
			}
		},
		created() {
		},
		mounted() {
			if(editorModal){
				editorModal.destroy();
				editorModal=null;
			}
			this.cheditorInit();
		},
		beforeDestroy(){
		},
		methods:{
			/*编辑器初始化*/
			cheditorInit(){
				const self = this;
				CKEDITOR.disableAutoInline = true;
				CKEDITOR.config.fontSize_defaultLabel = '16';
				CKEDITOR.dtd.$removeEmpty.i = false;
				CKEDITOR.config.colorButton_colorsPerRow = 9;
				CKEDITOR.editorConfig = function (config) {
					config.protectedSource.push(/\n/g);
				};
				// 渲染编辑器
				editorModal = CKEDITOR.inline('ckEditorTextarea', {
					allowedContent:true,
					fullPage:true,
					// extraPlugins: 'sharedspace,widgetselection,placeholder,magicline,hyperlink',
					extraPlugins: 'sharedspace,widgetselection,placeholder,magicline',
					uiColor: "#FBFBFB",
					magicline_color:'rgba(0,0,0,0)',
					magicline_everywhere: false,
					//关闭底部标签显示
					removePlugins:'elementspath',
					sharedSpaces: {top: 'ck_editor_tool_header'},
					toolbar:[
						['Undo', 'Redo', 'CopyFormatting', 'RemoveFormat'],
						['Format','FontSize','Bold','Italic','Underline','Strike','TextColor','BGColor'],
						['NumberedList','BulletedList','JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'],
						['HorizontalRule','Link']
					],
					resize_enabled:false,
					scayt_autoStartup:false,
					forcePasteAsPlainText:false,
				})
				CKEDITOR.dtd.$editable  = true;
				insertCkEditorFunction(this)
				editorModal.on('instanceReady',function(event){
					editorModal._.editable.$.id="ckEditor"
					editorModal._.editable.$.classList.add("ckEditor___boxo1","cheditor__defult__kajp90zxsad");
					// 设置初始内容
					editorModal.setData(self.content);
					// 监听内容变更事件
					editorModal.on("change", function() {
					  self.$emit("sendContnet", editorModal.getData());
					});
				})
			},
			/*获取编辑器内容*/
			getEditorContent(){
				if(editorModal)return editorModal.getData();
				return "";
			},
		},
	}
	function insertCkEditorFunction(that) {
	    $.extend(CKEDITOR, {
	        __textareaValidate:function(){
	        },
	        __textareaCommit:function(d){
	            // console.log("数据",d.element)
	        },
	        __textareaSetup:function(d){
	            // console.log('Setup',d)
	            moduleDialogData.widget=d.widget;
	            if(moduleDialogData.widget && !moduleDialogData.showStatus){
	                moduleDialogData.showStatus = true;
	            }
	        },
	        __dialogOnLoad:function(d){
	            var t = d.dialog, a = $(t.parts.dialog.$).parent();
	            $(t.parts.dialog.$).css('position','fixed');
	            a.removeClass("cke_reset_all cke_dialog_container").find(".cke_dialog_ui_textarea").hide();
	            a.find(".cke_dialog_close_button,.cke_dialog_ui_button_cancel,.cke_dialog_ui_button_ok").removeAttr("title");
	        },
	        __dialogOnShow:function(d){
	            moduleDialogData.dialog=d.dialog;
	            moduleDialogData.type=d.type;
	            if(d.type){
	                /*修改超链接*/
	                if(d.type=='link'){
	                    $('#'+moduleDialogData.dialog._.contents.info.linkType.domId).find("select").val('url');
	                    $('#'+moduleDialogData.dialog._.contents.info.urlOptions.domId).find("select").val('');
	                    $('#'+moduleDialogData.dialog._.contents.info.urlOptions.domId).parents('tr').show();
						that.linkData.openLink(d.data);
	                }
	            }
	        },
	        __dialogOnHide:function(d){
	            // console.log('OnHide',d)moduleDialogData.showStatus
	            moduleDialogData={dialog:null,widget:null,type:'',showStatus:false,};
	        },
	        __dialogOnOk:function(d){
	            // console.log('OnOk',d.dialog)
	        },
	        __dialogOnCancel:function (d) {
	            // console.log('OnCancel',d)
	        }
	    })
	}
	function insertHtml(html,type){
		if(editorModal.getData()){
			insertAfterCkeditor(1);
		}
		if(html){
			editorModal.insertHtml(html);
			editorModal.focus();
			if(type){
				insertAfterCkeditor(2);
			}
		}
	}
	/*寻找当前字段父级别*/
	function findSelectionParentCkeditor(currentSelectionJq) {
	    var result = {
	        total:0,
	        pTotal:0,
	    };
	    try {
	        for (let i=0;i<20;i++){
	            if(currentSelectionJq.parent().hasClass('cheditor__defult__h185448')){
	                break;
	            }else{
	                currentSelectionJq = currentSelectionJq.parent();
	            }
	        }
	        $(`<div>${currentSelectionJq.prop('outerHTML')}</div>`).find("*").each(function(index, element) {
	            if($(this).prop('tagName')!='BR'){
	                if($(this).prop('tagName')=='P'){
	                    result.pTotal++;
	                }
	                result.total++;
	                if(result.total>1){
	                    return false;
	                }
	            }
	        });
	    }catch (e) {
	        result = {
	            total:100,
	            pTotal:100,
	        }
	    };
	    return result;
	}
	function insertAfterCkeditor(next_status) {
		//插入新段落并获取焦点
		if(!next_status)next_status=-1;
		try {
			editorModal.focus();
			var range;
			let e = editorModal.getSelection().getStartElement().getParents();
			let eDomEdit = null;
			if(e){
				try {
					e.forEach((item,index)=>{
						if(item.$.getAttribute("id")=="ckEditor"){
							eDomEdit = e[index+1];
							throw Error();
						}
					})
				} catch(error) {}
			}
			var selectResult =  findSelectionParentCkeditor($(editorModal.getSelection().getStartElement().$));
			if(selectResult.pTotal==1 && selectResult.total==1){
				if(next_status==1){
					//当前是p标签
					// return false;
				}else if(next_status==2 && eDomEdit.getNext()){
					selectResult =  findSelectionParentCkeditor($(eDomEdit.getNext().$).prop('outerHTML'));
					if(selectResult.pTotal==1 && selectResult.total==1){
						// 获取焦点
						range = editorModal.createRange();
						// 将光标移至下段落
						range.moveToPosition(eDomEdit.getNext(), CKEDITOR.POSITION_AFTER_START);
						range.select();
						range.scrollIntoView();
						return false;
					}
				}
			}else if (eDomEdit.getNext() && next_status!=-1) {//当下面有P标签
				selectResult = findSelectionParentCkeditor($(eDomEdit.getNext().$));
				if(next_status==3){
					// 将光标移至最末
					range = editorModal.createRange();
					range.moveToElementEditEnd(editorModal.editable());
					range.select();
					range.scrollIntoView();
					insertAfterCkeditor();
					return false;
				}else if(selectResult.pTotal==1 && selectResult.total==1){
					// 获取焦点
					range = editorModal.createRange();
					// 将光标移至下段落
					range.moveToPosition(eDomEdit.getNext(), CKEDITOR.POSITION_AFTER_START);
					range.select();
					range.scrollIntoView();
					return false;
				}
			}
			if(next_status==3){
				// 将光标移至最末
				range = editorModal.createRange();
				range.moveToElementEditEnd(editorModal.editable());
				range.select();
				range.scrollIntoView();
				insertAfterCkeditor();
				return false;
			}
	
			let tpl= CKEDITOR.dom.element.createFromHtml('<p><br></p>');
			(tpl).insertAfter(eDomEdit);
			let index = tpl.getIndex();
			// 获取焦点
			range = editorModal.createRange();
			// 将光标移至新增段落
			range.moveToPosition(tpl, CKEDITOR.POSITION_AFTER_START);
			range.select();
			range.scrollIntoView();
		}catch (e) {
			console.log(e)
		};
	}
</script>

<style>
	.cheditor__defult__kajp90zxsad h1{
		font-size: 26px;
		line-height: 34px;
	}
	.cheditor__defult__kajp90zxsad h2{
		font-size: 24px;
		line-height: 30px;
	}
	.cheditor__defult__kajp90zxsad h1, .cheditor__defult__kajp90zxsad h2, .cheditor__defult__kajp90zxsad h3, .cheditor__defult__kajp90zxsad h4 {
	    color: rgba(0, 0, 0, 0.9);
	    font-weight: 600;
	    letter-spacing: 1px;
	}
	.cheditor__defult__kajp90zxsad p{
		margin: 0;
		padding: 0;
	}
	.tp-editor-warp .ck_editor_tool_menu{
		height: 45px;
		position: sticky;
		padding-top: 3px;
		/* overflow: hidden; */
		box-sizing: border-box;
		top: -24px;
		background: #fbfbfb;
		z-index: 2;
	}
	.tp-editor-warp .ck_editor_tool_header #cke_ckEditor{
		justify-content: flex-start;
	}
	.tp-editor-warp .ck_editor_tool_header_default{
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.tp-editor-warp .ck_editor_tool_header_default .ck_editor_tool_header_default-item{
		height: 100%;
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 0 10px;
		position: relative;
	}
	.ck_editor_tool_header_default-item>span{
		font-size: 17px;
		color: #333;
		transition: color 0.2s;
	}
	.ck_editor_tool_header_default-item:hover>span{
		color: #285AF9;
	}
	#ck_editor_tool_header .cke_chrome{border: 0;}
	.cke_dialog{
		top: -10000px!important;
		left: -10000px!important;
	}
	.cke_dialog_background_cover{display: none;}
	.tp-editor-warp{
		border: 1px solid #FBFBFB;
	}
	#ckEditor{
		min-height: 600px;
		padding: 20px;
	}
	
	.help-content-save-box-default .ant-modal-body{
		width: 100%;
		max-height: 60vh;
		overflow-y: auto;
	}
</style>
<style scoped>
	.editor-images-upload{
		width: 100%;
		font-size: 0;
		margin: 0;
		padding: 0;
		display: flex;
	}
	.editor-image-preview{
		text-align: center;
		width: 128px;
		height: 128px;
		background: #FAFAFA;
		border: 1px solid #DADDE1;
		box-sizing: border-box;
		border-radius: 2px;
		cursor: pointer;
		margin-right: 13px;
		position: relative;
	}
	.editor-images-del{
		position: absolute;
		top: -12px;
		right: -12px;
		width: 24px;
		height: 24px;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #929299;
		transition: color 0.2s;
	}
	.editor-images-del>span{
		font-size: 19px;
		line-height: 1;
	}
	.editor-images-del:hover{
		color: #ff4d4f;
	}
	.editor-images-uploading{
		position: absolute;
		z-index: 2;
		background-color: rgba(0,0,0,0.5);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		cursor: auto;
		padding: 0 20px;
		display: flex;
		align-items: center;
	}
	.editor-images-uploading .editor-images-uploading-item{
		color: #ffffff;
		font-size: 16px;
		text-align: center;
		width: 100%;
	}
	.editor-image-add{
		text-align: center;
		font-size: 12px;
		color: #949494;
		width: 128px;
		height: 128px;
		background: #FAFAFA;
		border: 1px dashed #E1E4E7;
		box-sizing: border-box;
		border-radius: 2px;
		cursor: pointer;
		padding-top: 36px;
		position: relative;
		transition: all 0.2s;
	}
	.editor-image-add:hover{border-color:#285AF9;}
	.editor-upload-img-file{
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		width: 0;
		height: 0;
		cursor: pointer;
		z-index: 1;
		display: none;
	}
</style>
import config from "./config.js";
import apiRequest from "./request.js";
import { Modal,message } from 'ant-design-vue';
import md5 from "md5";
import { createVNode,h } from 'vue';
import { QuestionCircleOutlined,ExclamationCircleOutlined } from '@ant-design/icons-vue';
const baseFunc = {
	
	//秒=>时:分:秒
	formatToSeconds(value) {
		let result = parseInt(value)
		let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
		let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result /
			60 % 60));
		let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
	
		let res = '';
		if (h !== '00') res += `${h}:`;
		res += `${m}:`;
		res += `${s}`;
		return res;
	},
	/*计算视频位置信息*/
	calculateVideoSize(item,containerW,containerH){
		let orgW = parseInt(item.width),orgH=parseInt(item.height);
		let relW = 0,relH=0;/*真实宽高*/
		if(orgW>orgH){
			relW = containerW;
			relH = relW*orgH/orgW;
		}else{
			relH = containerH;
			relW = relH*orgW/orgH;
		}
		return {w:relW,h:relH};
	},
	/*数组中根据id查找索引id*/
	findArrIndex(arr,key,val){
		return new Promise(function(resolve, reject) {
			let index = -1;
			let arrData = JSON.parse(JSON.stringify(arr))
			for(let i=0;i<arrData.length;i++){
				if(arrData[i] && arrData[i][key]==val){
					index = i;
					break;
				}
			}
			if(index>-1){
				resolve(index)
			}else{
				reject(-1)
			}
		})
	},
	/*获取文字数量*/
	wordLengthFunc(str){
		if(str){
			return str.length;
		}else{
			return 0;
		}
	},
	/*检测用户是否登陆*/
	checkUserLogin(that,status,tipStatus){
		if(!that)that=this;
		return new Promise(function(resolve, reject) {
			if(that && that.userinfo){
				resolve();
			}else{
				that.$apiRequest.post('/user/getUserInfo', {
				}).then(res => {
					if(status&&that)that.userinfo = res.data.user;
					resolve();
				}).catch(err => {
					if(err.data&&tipStatus)baseFunc.checkState(err.data).then(res=>{}).catch(err=>{})
					reject();
				})
			}
		})
	},
	/* 状态判断data:示例
		data={
			code:11001,
			message:"请充值"
		}
	*/
	checkState(data) {
		if(!data)data={code:1000,message:''};
		return new Promise(function(resolve, reject) {
			let content_tip_msg='';
			switch (data.code) {
				case 11000:
					new LoginRegistration({
						info: {type: "login"},
						extraData: {},
						success: function(info, extraData) {
							/*登录成功后回调*/
							resolve(info);
							// console.log('确认',info, extraData)
						},
						cancel: function(info, extraData) {
							reject(info);
							/*取消或者关闭时回调*/
							// console.log('取消',info, extraData)
						}
					});
				break;
				case 11001:
					if(typeof data.link_example !='undefined' && data.link_example){
						content_tip_msg=h("div",null,[
								h("p", { style: 'color:red;' }, data.message),
								h("div",{ style: 'text-align: center;' },[
									h("div",{onClick: () =>{
										let targetDom = document.createElement('input'); //创建input节点
										targetDom.value = data.link_example; // 给input的value赋值
										document.body.appendChild(targetDom) // 向页面插入input节点
										targetDom.select() // 选中input
										try {
											document.execCommand('Copy') // 执行浏览器复制命令
											message.success('复制成功！');
										} catch (e) {
										}
										targetDom.remove();
									},style: 'cursor: pointer;width: 100%;border: 1px dashed #c0c9e6;padding: 4px;height: 80px;display: flex;align-items: center;justify-content: center;color: #285af9;border-radius: 4px;font-size: 16px;word-break: break-all;line-height: 1;'}, [
										h("span", data.link_example),
									]),
									h("div", { style: 'color:#999999;margin-top:8px;font-size:12px;'}, "复制链接预览"),
								])
							]
						)
					}else if(typeof data.code_example !='undefined' && data.code_example){
						content_tip_msg=h("div",null,[
								h("p", { style: 'color:red;' }, data.message),
								h("div",{ style: 'text-align: center;' },[
									h("div",{style: 'cursor: pointer;width: 100%;border: 1px dashed #c0c9e6;padding: 4px;height: 280px;display: flex;align-items: center;justify-content: center;color: #285af9;border-radius: 4px;font-size: 16px;word-break: break-all;line-height: 1;'}, [
										h("img", { style: 'width:240px;height:auto;',src:data.code_example }, null),
									]),
									h("div", { style: 'color:#999999;margin-top:8px;font-size:12px;'}, "微信扫码体验"),
								])
							]
						)
					}else{
						content_tip_msg = createVNode('div', { style: 'color:red;' }, data.message);
					}
					
					
					Modal.confirm({
						title: '提示',
						content:()=>content_tip_msg,
						icon: () =>createVNode(ExclamationCircleOutlined,{ style:'color:#faad14' }),
						width:"540px",
						cancelText:"取消",
						okText:"去充值",
						onOk() {
							window.open(config.upgradeUrl)
							// reject&&reject("error")
						},
						onCancel(){
							// reject&&reject("error")
						},
					});
				break;
				case 11900:
					Modal.warning({
						title: '提示',
						content: data.message,
						cancelText:"取消",
						okText:"确认",
						onOk() {reject("error")},
						onCancel(){reject("error")},
					});
				break;
				case 11901:
					Modal.warning({
						title: '提示',
						content: data.message,
						cancelText:"取消",
						okText:"确认",
						onOk() {reject("error")},
						onCancel(){reject("error")},
					});
					break;
				default:
					Modal.warning({
						title: '提示',
						content: data.message,
						cancelText:"取消",
						okText:"确认",
						onOk() {reject("error")},
						onCancel(){reject("error")},
					});
				break;
			}
		})
	},
	
	
	checkQiniuUrlSame(newVal,oldVal){
		try{
			let newParse = parseUrl(newVal);
			let oldParse = parseUrl(oldVal);
			if(parse['path']!=oldParse['path']){
				return true;
			}else{
				return false;
			}
		}catch(e){
			return false;
		}
	},
	/*
	 *七牛云文件加密
	 *@url：视频地址
	 *@frame：视频帧-传此参数则返回的加密地址为图片
	 */
	getQiniusign(url, frame) {
		if(!url)return "";
		// 对 URL 进行解析
		var parse = parseUrl(url);
		var query = [];
		if (typeof parse['query'] != 'undefined' && parse['query']) {
			for (let item in parse['query']) {
				if (item != 'sign' && item != 't' && item!='vframe') {
					if (parse['query'][item]) {
						query.push(item + "=" + parse['query'][item]);
					} else {
						query.push(item);
					}
				}
			}
		}
		var timestamp = Date.parse(new Date()) / 1000;
		// 设置有效时间为2个小时
		var time = timestamp + 1 * 60 * 60;
		if (typeof frame == 'number' || typeof frame == 'string') {
			/*获取视频封面图*/
			time = timestamp + 20*60;
			query.push("vframe/jpg/offset/" + frame);
		}
		var t = time.toString(16);
		// 按规定格式拼接字符串
		var jointStr = config.qiniuTimeKey + parse['path'] + t;
		// 进行 md5 加密并进行小写转换
		query.push("sign=" + md5(jointStr).toLowerCase());
		query.push("t=" + t);
		return "https:" + config.qiniuMediaDomain + parse['path'] + "?" + query.join('&');
	},

	/*
	 *阿里云oss图片加密
	 *@url：图片地址
	 *@frame：视频帧-传此参数则返回的加密地址为图片
	 */
	getAliOssAuthKey(url, extra) {
		// 对 URL 进行解析
		var parse = parseUrl(url);
		var query = [];
		if (typeof parse['query'] != 'undefined' && parse['query']) {
			for (let item in parse['query']) {
				if (item != 'auth_key') {
					if (parse['query'][item]) {
						query.push(item + "=" + parse['query'][item]);
					} else {
						query.push(item);
					}
				}
			}
		}
		var timestamp = Date.parse(new Date()) / 1000;
		// 设置有效时间为1分钟
		var time = timestamp + 10 * 60;
		// 按规定格式拼接字符串
		var keyS = parse['path'] + '-' + time + '-0-0-' + config.aliMainKey;
		// 进行 md5 加密并进行小写转换
		var auth_key = time + '-0-0-' + md5(keyS).toLowerCase();
		// 进行 md5 加密并进行小写转换
		query.push("auth_key=" + auth_key);
		return "https:" + config.aliOSS.outDomain + parse['path'] + "?" + query.join('&');
	},
	/*获取时间-返回格式 2022/02/09/*/
	getDateString(format) {
		if (!format) format = '/';
		var zero = function(value) {
			if (value < 10) {
				return '0' + value;
			}
			return value;
		};
		var myDate = new Date();
		var year = myDate.getFullYear();
		var month = zero(myDate.getMonth() + 1);
		var day = zero(myDate.getDate());
		return year + format + month + format + day + format;
	},
	/*获取唯一uuid*/
	gen_uuid() {
		var s = [];
		var hexDigits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (var i = 0; i < 36; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
		s[8] = s[13] = s[18] = s[23] = "_";
		var uuid = s.join("");
		return uuid;
	},
	/*获取文件后缀名*/
	getType(filename, dot) {
		if (!filename) return "";
		if (dot) {
			return filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
		} else {
			return filename.substring(filename.lastIndexOf("."), filename.length).toLowerCase();
		}

	},
	/*获取随机数字*/
	genID(length) {
		return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
	},
	/*size-转b*/
	converSize(size) {
		size = parseInt(size);
		var result = "";
		if (size < 1024) { //如果小于0.1KB转化成B
			result = size.toFixed(2) + "B";
		} else if (size < 1024 * 1024) { //如果小于0.1MB转化成KB
			result = (size / 1024).toFixed(2) + "KB";
		} else if (size < 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB
			result = (size / (1024 * 1024)).toFixed(2) + "MB";
		} else { //其他转化成GB
			result = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
		}
		var sizestr = result + "";
		var len = sizestr.indexOf("\.");
		var dec = sizestr.substr(len + 1, 2);
		if (dec == "00") { //当小数点后为00时 去掉小数部分
			return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
		}
		return sizestr;
	},
	/*计算视频*/
  videoJsWidthHeight:function(w,h,s_w,s_h){
	if(!s_h||s_h<1){s_h = 250}
	let result = {
	  w:0,
	  h:0,
	}
	if(typeof status!='undefined'&&status==1){
	  result.w = s_w
	  result.h = result.w*h/w
	  if(result.h>s_h){result.h=s_h}
	}else{
	  if(w/h>=s_w/s_h){
		if(w<=s_w){
		  result.w = s_w
		  result.h = result.w*h/w
		}else{
			result.w = s_w
			result.h = result.w*h/w
		}
	  }else{
		  if(h<=s_h){
			result.w = s_w
			result.h = result.w*h/w
		  }else{
			result.h = s_h
			result.w = result.h*w/h
		  }
	  }
	}
	
	return result
  },
	/*播放量转换*/
	playbackConversion(virtual,real){
		if(virtual){
			virtual = parseFloat(virtual);
			if(isNaN(virtual))virtual=0;
		}else{
			virtual = 0;
		}
		if(real){
			real = parseFloat(real);
			if(isNaN(real))real=0;
		}else{
			real = 0;
		}
	    let number = real+virtual;
	    if(number<10000){
	        return number;
	    }else if(number<10000*1000){
	        return Math.floor(number*100/10000) / 100+'w';
	    }else{
	        return Math.floor(number*100/10000000) / 100+'kw';
	    }
	},
	/*秒转时间 示例：120 -> 02:00
	 * */
	converNumberToTime(time) {
		time = parseInt(time);
		let minute = 0,
			second = 0,
			hour = 0;
		if (time <= 60) {
			return '00:' + plusOne(time);
		} else if(time<3600) {
			minute = parseInt(time / 60);
			second = parseInt(time % 60);
			return plusOne(minute) + ":" + plusOne(second);
		}else{
			hour = parseInt(time/3600);
			minute = parseInt((time%3600)/60);
			second = parseInt((time%3600)%60);
			return plusOne(hour) + ":"+plusOne(minute) + ":" + plusOne(second);
		}
	},
	/*检测文件格式*/
	checkFileExt(ext, type) {
		let result = '';
		let arr_qf = ['xls', 'xlsx', 'docx', 'doc', 'ppt', 'pptx', 'pdf', 'gif', 'jpg', 'jpeg', 'png', 'swf', 'psd',
			'bmp', 'tiff', 'tiff', 'jpc', 'jp2', 'jpx', 'jb2', 'swc', 'iff', 'wbmp', 'count'
		];
		switch (type) {
			case "img":
				/*验证图片*/
				arr_qf = ['gif', 'jpg', 'jpeg', 'png', 'swf', 'psd', 'bmp', 'tiff', 'tiff', 'jpc', 'jp2', 'jpx',
					'jb2', 'swc', 'iff', 'wbmp', 'count'
				];
				break;
			case "file":
				/*验证文件*/
				arr_qf = ['xls', 'xlsx', 'docx', 'doc', 'ppt', 'pptx', 'pdf'];
				break;
			case "shengma":
				/*验证生码*/
				arr_qf = ['xls', 'xlsx', 'csv'];
				break;
			case "video":
				/*验证视频*/
				arr_qf = ['mp4', 'flv', 'avi', 'mov'];
				break;
			case "audio":
				/*验证音频*/
				arr_qf = ['mp3', 'm4a', 'wav', 'ogg', 'aiff', 'svcd', 'vcd'];
				break;
			case "media":
				/*验证媒体(音视频)*/
				arr_qf = ['mp4', 'flv', 'avi', 'mov', 'mp3', 'm4a', 'wav', 'ogg', 'aiff', 'svcd', 'vcd'];
				break;
		}
		if (!containsUYTHSKJ(arr_qf, ext)) {
			switch (type) {
				case "img":
					/*验证图片*/
					result = '不支持“' + ext + '”格式文件，请上传正确的图片'
					break;
				case "file":
					/*验证文件*/
					result = '不支持“' + ext + '”格式文件，请上传正确的文件'
					break;
				case "shengma":
					/*验证生码*/
					result = '不支持“' + ext + '”格式文件，请上传正确的文件'
					break;
				case "video":
					/*验证视频*/
					result = '不支持“' + ext + '”格式文件，请上传正确的视频'
					break;
				case "audio":
					/*验证音频*/
					result = '不支持“' + ext + '”格式文件，请上传正确的音频'
					break;
				case "media":
					/*验证媒体(音视频)*/
					result = '不支持“' + ext + '”格式文件，请上传正确的媒体文件'
					break;
				default:
					result = '不支持“' + ext + '”格式文件，请上传正确的图片或文件'
					break;
			}
		}
		return result;
	}
}

export default baseFunc;

/*共用方法*/
function parseUrl(url) {
	let urlObj = {
		protocol: /^(.+)\:\/\//,
		host: /\/\/(.+?)[\?\#\s\/]/,
		path: /\w(\/.*?)[\?\#\s]/,
		query: /\?(.+?)[\#\/\s]/,
		hash: /\#(\w+)\s$/
	};
	url += ' ';

	function formatQuery(str) {
		return str.split('&').reduce((a, b) => {
			let arr = b.split('=');
			a[arr[0]] = arr[1];
			return a
		}, {})
	}
	for (let key in urlObj) {
		let pattern = urlObj[key];
		urlObj[key] = key === 'query' ? (pattern.exec(url) && formatQuery(pattern.exec(url)[1])) : (pattern.exec(url) &&
			pattern.exec(url)[1])
	}
	return urlObj
}

function containsUYTHSKJ(arr_qf, obj) {
	for (let i = 0; i < arr_qf.length; i++) {
		if (arr_qf[i] === obj) {
			return true;
		}
	}
	return false;
}
/*数字补0*/
function plusOne(time) {
	if (time < 10) {
		return '0' + time;
	} else {
		return time;
	}
}
